import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Form from 'elements/Form/Form'
import { isValidEmail } from 'utils/formValidator'
import fetch from 'isomorphic-fetch'
import {
  Box,
  Icon,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input,
  IconButton
} from '@chakra-ui/core'
import { SUBSCRIBE_API_ROUTE } from 'config/routes'

export default function SubscribeForm () {
  const { handleSubmit, errors, register, formState } = useForm()
  const [isSuccess, setIsSuccess] = useState(false)

  function validateEmail (value) {
    let error
    if (!value) {
      error = 'Email is required'
    } else if (!isValidEmail(value)) {
      error = 'Email is invalid'
    }
    return error || true
  }

  const onSubmit = async ({ email }) => {
    try {
      await fetch(SUBSCRIBE_API_ROUTE, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, action: 'subscribe' })
      })
      setIsSuccess(true)
    } catch (e) {
      // Fail silent for now
      setIsSuccess(true)
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Box >

        <InputGroup>
          <InputLeftElement fontSize="25px">
            { isSuccess ? <Icon color="green.300" name="check"/> : <Icon color="gray.500" name="email"/> }
          </InputLeftElement>

          <Input ref={register({ validate: validateEmail })} name="email" type="email" pr="2.5rem" placeholder="Join our newsletter" />
          <InputRightElement width="2.5rem">
            <IconButton
              fontSize="30px"
              icon="submit"
              isDisabled={formState.isSubmitting}
              variantColor="primary"
              variant="link"
              isRound={true}
              type="submit"
              // className={classes.iconButton}
            />
          </InputRightElement>
        </InputGroup>

      </Box>
    </Form>

  )
}
