import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from 'elements/Sidebar/MenuItem'
import { Box } from '@chakra-ui/core'
import { uid } from 'react-uid'

export default function SideMenu ({ items, ...rest }) {
  return (
    <Box as="nav" {...rest}>
      {items.map(entry => (
        <MenuItem
          name={entry.text}
          icon={entry.icon}
          url={entry.url}
          key={uid(entry.text)}
          entries={entry.children}
        />
      ))}
    </Box>
  )
}

SideMenu.propTypes = {
  items: PropTypes.array.isRequired
}
