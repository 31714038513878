import arrayMove from 'array-move'
import { v4 as uuid } from 'uuid'

// Find item in state by ID
export const getById = (uid, item, path = []) => {
  const { id, type, elements } = item
  if (type) { path = path.concat(type) }

  // eslint-disable-next-line eqeqeq
  if (id == uid) {
    return { ...item, path }
  }
  let res
  for (let index = 0; index < elements.length; index++) {
    res = getById(uid, elements[index], path)
    if (res) { return res }
  }
}
export const getSchemaPath = (o, p) => p.reduce((a, v) => a.elements[v], o)

// Update (or delete with emptying payload) an item by ID
export const updateById = (uid, item, update) => {
  const { id, elements } = item
  // eslint-disable-next-line eqeqeq
  if (id == uid) {
    // console.log('HER')
    return { ...item, ...update }
  }
  for (let index = 0; index < elements.length; index++) {
    item.elements[index] = updateById(uid, elements[index], update)
  }
  return item
}

export const emptyItem = { properties: {}, elements: [], id: null, type: null }

export const moveElement = arrayMove

// Add uids to definition element
export const addIds = definition => {
  if (!definition) return
  if (Array.isArray(definition)) {
    for (let index = 0; index < definition.length; index++) {
      definition[index] = addIds(definition[index])
    }
  } else {
    definition.id = uuid()
    definition.elements = addIds(definition.elements)
  }

  return definition
}
