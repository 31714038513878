import React, { forwardRef, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import EditMode from 'elements/CodeBuilder/EditMode'
import BuildMode from 'elements/CodeBuilder/BuildMode'
import Link from 'elements/Link/Link'
import {
  Flex,
  Box,
  Button
} from '@chakra-ui/core'

const EditorButton = forwardRef((props, ref) => <Button
  textTransform="uppercase"
  type="submit"
  fontSize=".8em"
  variant="solid"
  variantColor="dark"
  size="md"
  ref={ref}
  m={1}
  {...props}
/>)
EditorButton.displayName = 'EditorButton'

export default function EditorFrame (props) {
  const { children, onChange, value, builderOptions, buildSchema, dragHandleComponent, elementComponent, levelComponent, editorComponent, toBuilder, fromBuilder, visualDocumentationUrl, codeDocumentationUrl } = props

  const [visual, setVisual] = useState(true)
  const [done, setDone] = useState(false)
  const [exit, setExit] = useState(false)

  const [data, setData] = useState(value)

  const changeEditor = useCallback(
    (flag) => {
      setVisual(flag)
      setDone(true)
    },
    [setDone]
  )

  const onExit = useCallback(
    () => {
      setExit(true)
      setDone(true)
    },
    [setDone]
  )

  const onDone = useCallback(
    (value) => {
      if (exit) onChange(value)
      else setData(value)
    },
    [setData, exit, onChange]
  )

  // useEffect(() => {
  //   if (done) {
  //     onChange(editValue)
  //   }
  // }, [done, onChange, editValue])

  const docsUrl = (visual && visualDocumentationUrl) || (!visual && codeDocumentationUrl)
  return (
    <Flex h="100%" flexDirection="column">
      <Flex bg="dark.500" justifyContent="space-between">
        <Box >
          { visual
            ? <EditorButton leftIcon="code" title="Switch to expert code view" onClick={() => changeEditor(false)}>Switch To Code Editor</EditorButton>
            : <EditorButton leftIcon="build" title="Switch to visual builder" onClick={() => changeEditor(true)}>Switch To Visual Builder</EditorButton>
          }
          { docsUrl
            ? <EditorButton as={Link} leftIcon="help" title="View related documentation in another window" to={docsUrl}>Help & Documentation</EditorButton> : null }
        </Box>
        <Box >
          <EditorButton leftIcon="submit" title="Save and exit editor" onClick={() => onExit()}>Save & Exit</EditorButton>
        </Box>
      </Flex>
      <Box p={0} flex="1">
        { visual
          ? children
          : <EditMode
            onDone={(value) => {
              onDone(value)
            }}
            done={done}
            initialValue={data ? JSON.stringify(JSON.parse(data), null, 2) : ''}
          />
        }
      </Box>
    </Flex>
  )
}

EditorFrame.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func,
  value: PropTypes.any,
  onDone: PropTypes.func,
  builderOptions: PropTypes.object,
  buildSchema: PropTypes.object,
  dragHandleComponent: PropTypes.func,
  elementComponent: PropTypes.func,
  levelComponent: PropTypes.func,
  editorComponent: PropTypes.func,
  toBuilder: PropTypes.func,
  fromBuilder: PropTypes.func,
  visualDocumentationUrl: PropTypes.string,
  codeDocumentationUrl: PropTypes.string
  // definition: PropTypes.any,
  // options: PropTypes.object
}
