export default {
  Auth: {
    mandatorySignIn: true,
    region: process.env.GATSBY_REGION,
    userPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
    identityPoolId: process.env.GATSBY_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_COGNITO_APP_CLIENT_ID
  },
  Storage: {
    region: process.env.GATSBY_REGION,
    bucket: process.env.GATSBY_BUCKET,
    identityPoolId: process.env.GATSBY_COGNITO_IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: 'api',
        endpoint: process.env.GATSBY_GRAPHQL_ENDPOINT,
        region: process.env.GATSBY_REGION
      }
    ]
  }
}
