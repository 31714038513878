import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import LayoutContext from 'contexts/LayoutContext'
import Link from 'elements/Link/Link'
import {
  PseudoBox,
  Icon,
  IconButton,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink
} from '@chakra-ui/core'
import { SERVICE_LIST_ROUTE, ACCOUNT_OVERVIEW_ROUTE, DOCS_ROUTE, BLOG_ROUTE } from 'config/routes'
import overlay from 'images/overlay.svg'

const BreadcrumbBar = ({ root, text, link }) => {
  const { title, sidebarVisible, setSidebarVisible } = useContext(LayoutContext)

  let rootRoute = '/'
  let rootText = ''
  let showSidebarToggle = false
  switch (root) {
    case 'dashboard':
      rootRoute = SERVICE_LIST_ROUTE
      showSidebarToggle = true
      rootText = 'Dashboard'
      break
    case 'docs':
      rootRoute = DOCS_ROUTE
      showSidebarToggle = true
      rootText = 'Documentation'
      break
    case 'blog':
      rootRoute = BLOG_ROUTE
      rootText = 'Blog'
      showSidebarToggle = true
      break
    // case 'services':
    //   rootRoute = SERVICE_LIST_ROUTE
    //   rootText = 'My Service'
    //   showSidebarToggle = true
    //   break
    case 'settings':
      rootRoute = ACCOUNT_OVERVIEW_ROUTE
      rootText = 'Settings'
      showSidebarToggle = true
      break
  }
  return (
    <PseudoBox
      mt="60px"
      h="80px"
      lineHeight="80px"
      px="60px"
      py="0"
      color="white"
      w="100%"
      position="relative"
      background="linear-gradient( to left, #31E8EF 0%, #3D1DAC 100%)"
      _before={ {
        content: '""',
        display: 'block',
        opacity: 0.2,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: `url(${overlay})`,
        backgroundSize: 'cover',
        // backgroundPosition: 'top left',
        backgroundRepeat: 'no-repeat'
      }}
      // className={clsx(classes.root, {
      //   [classes.dashboard]: root === 'dashboard',
      //   [classes.docs]: root === 'docs',
      //   [classes.blog]: root === 'blog',
      //   [classes.default]: root === 'default'
      // })}
    >
      {showSidebarToggle ? (

        <IconButton
          position="absolute"
          top={-4}
          left="20px"
          icon="hamburger"
          variant="unstyled"
          fontSize="25px"
          aria-label="Toggle sidebar"
          title="Toggle sidebar"
          color="white"
          zIndex="3"
          onClick={() => {
            setSidebarVisible(!sidebarVisible)
          }}
        />
      ) : (
        null
      )}
      {/* <Typography variant="h6" className={classes.title}>
        {root === 'dashboard'
          ? 'Dashboard'
          : root === 'docs'
          ? 'Documentation'
          : root === 'blog'
          ? 'Blog'
          : null}
      </Typography> */}

      <Breadcrumb

        fontWeight="600" fontSize="xl" fontStyle="italic" lineHeight="80px"
        spacing="8px"
        separator={<Icon color="gray.300" name="chevron-right" />}
      >
        { rootText
          ? <BreadcrumbItem>
            <BreadcrumbLink zIndex="3" as={Link} to={rootRoute}> {rootText}</BreadcrumbLink>
          </BreadcrumbItem>
          : null }

        {link && text ? (
          <BreadcrumbItem>
            <BreadcrumbLink zIndex="3" as={Link} to={link}>{text}</BreadcrumbLink>
          </BreadcrumbItem>
        ) : null }

        <BreadcrumbItem>
          <BreadcrumbLink zIndex="3" as={Link} >{title}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      {/* <Breadcrumbs
        separator={
          <NavigateNextIcon style={{ color: 'white' }} fontSize="small" />
        }
        aria-label="breadcrumb" */}

      {/* <Link
          color="inherit"
          to="/"
          //  onClick={handleClick}
          className={classes.link}
        >
          <HomeIcon className={classes.icon} />
        </Link> */}
      {/*
        <Link className={classes.link} to={rootRoute}>
          <Typography variant="h6" className={classes.title}>
            {rootText}
          </Typography>
        </Link>
        {link && text ? (
          <Link className={classes.link} to={link}>
            <Typography variant="h6" className={classes.title}>
              {text}
            </Typography>
          </Link>
        ) : (
          ''
        )}
      </Breadcrumbs> */}
    </PseudoBox>
  )
}
BreadcrumbBar.defaultProps = {
  root: 'default'
}

BreadcrumbBar.propTypes = {
  root: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string
}

export default BreadcrumbBar
