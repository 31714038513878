import React from 'react'
import PropTypes from 'prop-types'
import Layout from 'elements/Frame/Layout'
import DocMenu from 'elements/Sidebar/DocMenu'

const DocFrame = props => {
  const { children } = props

  return (
    <Layout root="docs" sidebar={<DocMenu />}>
      {children}
    </Layout>
  )
}

DocFrame.propTypes = {
  children: PropTypes.node.isRequired
}

export default DocFrame
