// Packages
export const PACKAGE_ROUTE = '/packages/:packageId'
export const PACKAGE_LIST_ROUTE = '/packages'

// Services
export const SERVICE_LIST_ROUTE = '/dashboard/services'
export const SERVICE_CONFIGURE_ROUTE = '/service/:serviceId/configure'
export const SERVICE_UPDATE_ROUTE = '/service/:serviceId/update'
export const SERVICE_CREATE_ROUTE = '/service/create'
export const SERVICE_OVERVIEW_ROUTE = '/service/:serviceId'

// Workflows
export const WORKFLOW_LIST_ROUTE = '/service/:serviceId/workflows'
export const WORKFLOW_CREATE_ROUTE = '/service/:serviceId/workflows/create'
export const WORKFLOW_UPDATE_ROUTE = '/service/:serviceId/workflows/update/:workflowId'
export const WORKFLOW_RUN_ROUTE = '/service/:serviceId/workflows/run/:workflowId'
export const WORKFLOW_LOGS_ROUTE = '/service/:serviceId/workflows/logs/:workflowId'

// Visual builders
export const EDITOR_WORKFLOW_ROUTE = '/editor/workflow'
export const EDITOR_COLLECTION_ROUTE = '/editor/collection'
export const EDITOR_VIEW_ROUTE = '/editor/view'

// Views
export const VIEW_LIST_ROUTE = '/service/:serviceId/views'
export const VIEW_CREATE_ROUTE = '/service/:serviceId/views/create'
export const VIEW_UPDATE_ROUTE = '/service/:serviceId/views/update/:viewId'

// Collections
export const COLLECTION_LIST_ROUTE = '/service/:serviceId/collections'
export const COLLECTION_CREATE_ROUTE = '/service/:serviceId/collections/create'
export const COLLECTION_UPDATE_ROUTE = '/service/:serviceId/collections/update/:collectionId'
export const COLLECTION_ITEMS_ROUTE = '/service/:serviceId/collection/items/:collectionId'
export const COLLECTION_INSIGHTS_ROUTE = '/service/:serviceId/insights'
export const COLLECTION_INSIGHT_ROUTE = '/service/insights/:workflowId' // TODO do we use this?

// Parameters
export const PARAMETER_CREATE_ROUTE = '/dashboard/parameters/create'
export const PARAMETER_UPDATE_ROUTE = '/dashboard/parameters/update/:parameterId'
export const PARAMETER_LIST_ROUTE = '/dashboard/parameters'

// Modules
export const MODULE_CREATE_ROUTE = '/dashboard/modules/create'
export const MODULE_UPDATE_ROUTE = '/dashboard/modules/update/:moduleId'
export const MODULE_LIST_ROUTE = '/dashboard/modules'
export const MODULE_TEST_ROUTE = '/dashboard/modules/test/:moduleId'

// Settings
export const ACCOUNT_OVERVIEW_ROUTE = '/settings/overview'
export const UPDATE_EMAIL_ROUTE = '/settings/email'
export const UPDATE_PASSWORD_ROUTE = '/settings/password'

// Auth
export const LOGIN_ROUTE = '/auth/login'
export const LOGOUT_ROUTE = '/auth/logout'
export const REGISTER_ROUTE = '/auth/register'
export const RESET_PASSWORD_ROUTE = '/auth/reset'

// General
export const WELCOME_ROUTE = '/welcome'
export const DOCS_ROUTE = '/docs'
export const BLOG_ROUTE = '/blog'
export const BILLING_ROUTE = '/'
export const MARKETPLACE_ROUTE = '/marketplace'
export const FEATURES_ROUTE = '/features'
export const SUBSCRIBE_ROUTE = '/subscribe'
export const CHECKOUT_ROUTE = '/checkout'
export const RECEIPT_ROUTE = '/receipt'
export const PRICING_ROUTE = '/pricing'
export const HELP_ROUTE = '/help'
export const ABOUT_ROUTE = '/company/about'
export const PRIVACY_ROUTE = '/legal/privacy'

// External services
export const GITHUB_ROUTE = 'https://github.com/lotusengine.com'
export const TWITTER_ROUTE = 'https://twitter.com/lotusengine'
export const FORUM_ROUTE = 'https://spectrum.chat/lotusengine'

// API TODO - needs to change on new API
export const SUBSCRIBE_API_ROUTE = `${process.env.GATSBY_API_ENDPOINT}`
// export const SUBSCRIBE_API_ROUTE = `${process.env.GATSBY_API_ENDPOINT}/subscribe`
export const CONTACT_API_ROUTE = `${process.env.GATSBY_API_ENDPOINT}/contact`
export const BILLING_API_ROUTE = `${process.env.GATSBY_API_ENDPOINT}/billing`
