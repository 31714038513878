import React from 'react'
import PropTypes from 'prop-types'
import Layout from 'elements/Frame/Layout'
import SettingsMenu from 'elements/Sidebar/SettingsMenu'

const SettingsFrame = props => {
  const { children } = props

  return (
    <Layout root="settings" sidebar={<SettingsMenu />}>
      {children}
    </Layout>
  )
}

SettingsFrame.propTypes = {
  children: PropTypes.node.isRequired
}

export default SettingsFrame
