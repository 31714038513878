import React from 'react'
import {
  IconButton
} from '@chakra-ui/core'
import Link from 'elements/Link/Link'
import { TWITTER_ROUTE } from 'config/routes'

export default function TwitterLink () {
  return (
    <Link title="Follow us on Twitter" to={TWITTER_ROUTE}>
      <IconButton
        px={0}
        mx={-2}
        icon="twitter"
        color="white"
        aria-label="Twitter"
        size="lg"
        variant="link"
      />
    </Link>
  )
}
