import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  PseudoBox,
  Flex,
  Box,
  Text,
  Divider,
  Image
} from '@chakra-ui/core'
import FooterNavList from 'elements/Footer/FooterNavList'
import TwitterLink from 'elements/Social/TwitterLink'
import ForumLink from 'elements/Social/ForumLink'
import GitHubLink from 'elements/Social/GitHubLink'
// import RocketChatLink from 'elements/Social/RocketChatLink'
import SubscribeForm from 'elements/Footer/SubscribeForm'
import logoImage from 'images/logo/logo-duo-light.svg'
import overlay from 'images/overlay.svg'

import {
  HELP_ROUTE,
  // BLOG_ROUTE,
  GITHUB_ROUTE,
  PRICING_ROUTE,
  ABOUT_ROUTE,
  PRIVACY_ROUTE,
  DOCS_ROUTE,
  EDITOR_WORKFLOW_ROUTE,
  EDITOR_COLLECTION_ROUTE,
  EDITOR_VIEW_ROUTE

} from 'config/routes'

export default function Footer (props) {
  const data = useStaticQuery(graphql`
    query SiteFooterQuery {
      site {
        siteMetadata {
          title
          description
          copyright         
        }
      }
    }
  `)

  return (
    <PseudoBox
      _before={{
        content: '""',
        display: 'block',
        opacity: 0.06,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: `url(${overlay})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
      position="relative"
      backgroundColor="#15212A"
      padding={['30px 40px', null, '30px 100px']}
      as="footer"
    >
      <Flex maxWidth={{ xs: '540px', sm: '720px', md: '960px', lg: '1140px' }} m="0 auto" flexDirection="column">
        <Flex align="space-between">

          <Box mr={5}>
            <Image
              w="150px"
              src={logoImage}
              alt="LotusEngine logo"
            />
          </Box>
          <Flex
            justifyContent="flex-end"
            flexGrow={1}>
            <ForumLink />
            <TwitterLink />
            <GitHubLink />
          </Flex>
        </Flex>
        <Divider mt="4" borderColor="gray.500" />
        <Flex
          align="space-between"
          width="100%"
          justify="space-between"
          wrap="wrap">
          <Box mt={4} width={{ xs: 'full', sm: 450, md: 'auto' }}>
            <SubscribeForm />
            <Text
              fontSize="xs"
              color="silver"
              mt="2"
            >
              {data.site.siteMetadata.copyright}
            </Text>
          </Box>
          <Flex
            mt={2}
            zIndex="3"
            justifyContent="space-evenly"
            flexGrow={1}>
            <FooterNavList

              title="Company"
              links={[
                { name: 'About Us', url: ABOUT_ROUTE },
                // { name: 'Our Blog', url: BLOG_ROUTE },
                { name: 'Privacy Policy', url: PRIVACY_ROUTE }
              ]}
            />
            <FooterNavList
              title="Services"
              links={[
                { name: 'Plans & Pricing', url: PRICING_ROUTE },
                { external: true, name: 'Our GitHub', url: GITHUB_ROUTE }
              ]}
            />
            {/*
            <FooterNavList

              title="Company"
              links={[
                { name: 'Plans & Pricing', url: PRICING_ROUTE },
                { name: 'About Us', url: ABOUT_ROUTE },
                // { name: 'Our Blog', url: BLOG_ROUTE },
                { name: 'Privacy Policy', url: PRIVACY_ROUTE }
              ]}
            />
            <FooterNavList
              title="Utilities"
              links={[
                { name: 'Workflow Editor', url: EDITOR_WORKFLOW_ROUTE },
                { name: 'Collection Editor', url: EDITOR_COLLECTION_ROUTE },
                { name: 'View Editor', url: EDITOR_VIEW_ROUTE }
              ]}
            /> */}
            <FooterNavList
              title="Support"
              links={[
                { name: 'Get Help', url: HELP_ROUTE },
                { name: 'Documentation', url: DOCS_ROUTE }
              ]}
            />
          </Flex>
        </Flex>
      </Flex>
    </PseudoBox>
  )
}

Footer.propTypes = {}
