import React, { useState, forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { uid } from 'react-uid'
import { Box, Flex, Text, Icon } from '@chakra-ui/core'
import Link from 'elements/Link/Link'

export default function MenuItem ({ name, url, icon, entries }) {
  const renderLink = useMemo(
    () =>
      // eslint-disable-next-line react/display-name
      forwardRef((itemProps, ref) => <Link {...itemProps} ref={ref} />),
    []
  )

  const hasNestedMenu = !!(entries && entries.length)
  const [subMenu, toggleSubMenu] = useState(false)

  const listItemProps = {
    button: hasNestedMenu
  }
  const nestedIconProps = {}

  const listItemTextProps = {
    fontWeight: '700',
    fontSize: '14px'
  }

  if (hasNestedMenu) {
    listItemTextProps.onClick = () => {
      toggleSubMenu(!subMenu)
    }
    listItemTextProps.cursor = 'pointer'
  } else {
    listItemProps.as = renderLink
    listItemProps.to = url
  }

  return (

    <Flex alignItems="flex-start" mb="6" {...listItemProps}>
      {icon ? <Icon mt={0} ml="0" mr="3" w="30" size="20px" color="gray.500" name={icon}/> : null}
      <Box ml={1}>
        <Flex align="center" {...listItemTextProps} >{name}  {hasNestedMenu ? (
          <Icon mt="1" color="gray.700" name={subMenu ? 'chevron-up' : 'chevron-down'} {...nestedIconProps} />
        ) : null}</Flex>
        {hasNestedMenu ? (
          <Box display={subMenu ? 'block' : 'none' } as="div" spacing={2} p={0}>
            {entries.map(entry => (
              <Text
                as={Link}
                display="block"
                to={entry.url}
                key={uid(entry.text)}
                lineHeight="30px"
                fontWeight="300"
                fontSize= "13px"
                color= "gray"
              >{entry.text}
              </Text>
            ))}
          </Box>
        ) : null}</Box>
    </Flex>

  )
}

MenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  entries: PropTypes.array,
  icon: PropTypes.string
}
