import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { AuthProvider } from 'contexts/AuthContext'
import { LayoutProvider } from 'contexts/LayoutContext'
import { ServiceProvider } from 'contexts/ServiceContext'
import { StateProvider } from 'contexts/StateContext'
import { ApolloProvider } from '@apollo/client'
import { apolloClient } from 'utils/apolloClient'
import { md5Hash } from 'utils/hashUtils'
import { HelmetProvider } from 'react-helmet-async'
import chakra from 'config/theme'
// import { navigate } from 'gatsby'
import { CSSReset, ThemeProvider } from '@chakra-ui/core'
import 'focus-visible'
import 'scss/core.scss'
import 'typeface-heebo'
import Amplify from '@aws-amplify/core'
import amplifyConfig from 'config/amplify'
import { Global, css } from '@emotion/core'
import { Auth } from 'aws-amplify'
Amplify.configure(amplifyConfig)

const getRandomInterval = (from, to) => {
  return (Math.random() * (to - from)) + from
}

// :focus:not(.focus-visible-added]) or :focus:not(.focus-visible dont seem to work w textarea
// https://www.npmjs.com/package/focus-visible

const GlobalStyles = css`
  textarea:focus {
    outline: none;
    border: 2px  solid  #3182CE !important;
    
  }
  .js-focus-visible  :focus:not(.focus-visible) { 
     outline: none !important;
     box-shadow: none !important;
     border-color: inherit !important;
   }
  
`

export default function Root ({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [emailHash, setEmailHash] = useState(null)
  const [userId, setUserId] = useState(false)
  const [accountId, setAccountId] = useState(false)
  const [title, setTitle] = useState(null)
  const [error, setError] = useState(null)
  // const [serviceId, setServiceId] = useState(null)
  // const [serviceName, setServiceName] = useState(null)
  const [service, setService] = useState(null)
  const [postAuthRoute, setPostAuthRoute] = useState(null)
  const [sidebarVisible, setSidebarVisible] = useState(true)
  const [loadingBarProgress, setLoadingBarProgress] = useState(false)
  const [loadingBarActive, setLoadingBarActive] = useState(false)
  const setAuthenticated = value => {
    setIsAuthenticated(value)
  }

  const onLoad = async () => {
    try {
      await Auth.currentSession()
      const CognitoUser = await Auth.currentAuthenticatedUser()
      const { username, attributes } = CognitoUser
      setIsAuthenticated(true)
      setUserId(username)
      setAccountId(username)
      setEmailHash(md5Hash(attributes.email))
      // eslint-disable-next-line no-empty
    } catch (_) {
    }
    setIsAuthenticating(false)
  }

  useEffect(() => {
    onLoad()
  }, [])

  useEffect(() => {
    if (loadingBarActive) {
      const interval = setInterval(() => {
        setLoadingBarProgress(getRandomInterval(loadingBarProgress, loadingBarProgress + 10))
        if (loadingBarProgress >= 100) {
          setLoadingBarProgress(0)
          setLoadingBarActive(false)
        }
      }, 500)

      return () => clearInterval(interval)
    } else if (loadingBarProgress > 0) {
      const interval = setInterval(() => {
        setLoadingBarProgress(loadingBarProgress + 10)
        if (loadingBarProgress >= 100) {
          setLoadingBarProgress(0)
        }
      }, 100)
      return () => clearInterval(interval)
    }
  }, [loadingBarProgress, loadingBarActive])

  const initialState = {
    service: { id: null, name: null }
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case 'setService':
        return {
          ...state,
          service: action.service
        }

      default:
        return state
    }
  }

  return (
    !isAuthenticating &&
    <ThemeProvider theme={chakra}>
      <CSSReset />
      <Global styles={GlobalStyles} />

      <HelmetProvider>
        <StateProvider initialState={initialState} reducer={reducer}>
          <LayoutProvider
            value={{
              error,
              setError,
              title,
              setTitle,
              sidebarVisible,
              setSidebarVisible,
              setLoadingBarActive
            }}
          >
            <AuthProvider
              value={{
                postAuthRoute,
                setPostAuthRoute,
                isAuthenticating,
                setIsAuthenticating,
                isAuthenticated,
                setAuthenticated,
                userId,
                setUserId,
                accountId,
                setAccountId,
                emailHash,
                setEmailHash
              }}
            >
              <ServiceProvider value={{ service, setService }}>
                <ApolloProvider client={apolloClient}>
                  {/* <ProgressBar /> */}
                  {children}</ApolloProvider>
              </ServiceProvider>
            </AuthProvider>
          </LayoutProvider>
        </StateProvider>
      </HelmetProvider>
    </ThemeProvider>

  )
}

Root.propTypes = {
  children: PropTypes.node
}
