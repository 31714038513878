module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1035,"sizeByPixelDensity":true,"showCaptions":true,"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-prismjs","options":{"noInlineHighlight":false,"aliases":{"shell":"bash"},"prompt":{"user":"user","host":"localhost","global":true}}},{"resolve":"gatsby-remark-external-links","options":{"target":null,"rel":"nofollow noopener noreferrer external"}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lotus Engine","short_name":"Lotus Engine","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"d5369b83ac75e08ace2e67c1e9de999c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-appsync/gatsby-browser.js'),
      options: {"plugins":[],"mandatorySignIn":false,"oauth":{},"cookieStorage":{"domain":"*.lotusengine.com","path":"/","expires":5,"secure":true},"clientMetadata":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
