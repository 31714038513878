export function isValidEmail (email) {
  return email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
}

// Should match cognito - 8+ letters and numbers upper/lower. Symbols allowed but not required.
export function isValidPassword (password) {
  return password && /^[a-z0-9^$*.[\]{}()?\-“!@#%&/,><’:;|_~`]{8,99}$/i.test(password)
}

// Check for valid JSON
export function isValidJson (value) {
  try {
    JSON.parse(value)
    return true
  } catch (_) {
    return false
  }
}
