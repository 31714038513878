import React from 'react'
import SideMenu from 'elements/Sidebar/SideMenu'

const docDoutes = [
  {
    text: 'Getting Started',
    icon: 'start',
    children: [
      {
        text: 'Introduction',
        url: '/docs/general/intro'
      },
      {
        text: 'Core Concepts',
        url: '/docs/general/concepts'
      },
      {
        text: 'Tutorials',
        url: '/docs/general/tutorials'
      },
      {
        text: 'Getting Help',
        url: '/docs/general/support'
      }
    ]
  },

  {
    text: 'UI Reference',
    icon: 'ui',
    children: [
      {
        text: 'Overview',
        url: '/docs/ui/intro'
      },
      {
        text: 'Channels',
        url: '/docs/ui/channels'
      },
      {
        text: 'Workflows',
        url: '/docs/ui/workflows'
      }
    ]
  },
  {
    text: 'Developer Docs',
    icon: 'code',

    children: [
      {
        text: 'Overview',
        url: '/docs/dev/intro'
      },
      {
        text: 'CLI Toolbelt',
        url: '/docs/dev/cli'
      },
      {
        text: 'Workflows',
        url: '/docs/dev/workflows'
      },
      {
        text: 'The Stream Object',
        url: '/docs/dev/stream'
      },
      {
        text: 'Storage',
        url: '/docs/dev/storage'
      },
      {
        text: 'JSONPath',
        url: '/docs/dev/jsonpath'
      }
    ]
  },
  {
    text: 'GraphQL Docs',
    icon: 'api',
    children: [
      {
        text: 'Overview',
        url: '/docs/api/intro'
      },
      {
        text: 'Authentication',
        url: '/docs/api/auth'
      },
      {
        text: 'API Reference',
        url: '/docs/api/reference'
      }
    ]
  }
]

export default function DocMenu () {
  return (

    <SideMenu items={docDoutes} />

  )
}
