import React from 'react'
import {
  IconButton
} from '@chakra-ui/core'
import Link from 'elements/Link/Link'
import { GITHUB_ROUTE } from 'config/routes'

export default function GitHubLink () {
  return (
    <Link title="Star us on GitHub" to={GITHUB_ROUTE}>
      <IconButton
        px={0}
        mx={-2}
        icon="github"
        color="white"
        aria-label="GitHub"
        size="lg"
        variant="link"
      /></Link>
  )
}
