import React from 'react'
import PropTypes from 'prop-types'
import { Box, SimpleGrid, Flex, Image } from '@chakra-ui/core'
import { Link, navigate } from 'gatsby'
import logoImage from 'images/logo/icon-color.svg'
import { HELP_ROUTE } from 'config/routes'
import banner from 'images/banner/enterprise.png'

const AuthFrame = props => {
  const {
    children
    // leftSidebar,
    // rightSidebar,
    // root,
    // . pageContext,

    // ...rest
  } = props

  return (
    <Box
      borderRadius= {5}
      bg="gray.50">
      <Link to="/">
        <Image top= {3}
          left= {3}
          opacity={0.8} position= "absolute" w="40px" src={logoImage} alt="LotusEngine logo" />
      </Link>
      <Flex
        minHeight="100vh"
        flexGrow= {1}
        px={10}
        pt={{ base: 50, md: 20 }}
        pb={20}
        justify="center"
        alignItems="center"
        spacing={0}
      >
        <SimpleGrid
          // borderColor="gray.200"
          // borderWidth={1}
          maxWidth="1200px" columns={{ sm: 1, md: 2 }}>
          {/* <Box
          p={10}
          pt={{ sm: 2, base: 10 }}
          maxWidth="1200px"
          flex="1"

        > */}
          <Box id="content" py={5} px={10}>
            {children}
          </Box>
          <Box rounded={4} bg="#1C232F">
            <Image
              onClick={() => {
                navigate(HELP_ROUTE)
              }}
              style={{ cursor: 'pointer' }}
              src={banner}
              aspectRatio={1.2}
            />
          </Box>
        </SimpleGrid>

      </Flex>
    </Box>
  )
}

AuthFrame.propTypes = {
  children: PropTypes.node.isRequired
}

export default AuthFrame
