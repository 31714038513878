import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  PseudoBox
} from '@chakra-ui/core'
import Editor from 'react-simple-code-editor'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-json'
import 'prism-themes/themes/prism-atom-dark.css'
// import 'prismjs/themes/prism-twilight.css' // for dark mode
// import 'prismjs/themes/prism.css'

export default function EditMode (props) {
  const { onDone, done, initialValue } = props

  const [editValue, setEditValue] = useState(initialValue)

  useEffect(() => {
    if (done) { onDone(editValue) }
  }, [onDone, done, editValue])

  // Count newlines and pad
  const lines = (initialValue.match(/\n/g) || []).length + 2

  // Determine padding needed (length of line number length)
  const pad = String(lines).length

  //  Make sharp stop editor gradient background padded to fit font size/style
  // const bg = `linear-gradient(90deg, #EDF2F7 ${20 + pad * 8}px, ${20 + pad * 8}px, #FFF 100%)` // Light
  const bg = `linear-gradient(90deg, #333333 ${20 + pad * 8}px, ${20 + pad * 8}px, #171717 100%)` // Dark

  // Create content with all line numbers and newline them
  const lineNos = [...Array(lines).keys()].slice(1).join('\\00000a')

  // Style pseudo
  const before = {
    fontFamily: 'Inconsolata, monospace',
    paddingTop: '10px',
    content: `"${lineNos}"`,
    width: `${20 + pad * 8}px`,
    position: 'absolute',
    whiteSpace: 'pre',
    paddingRight: '10px',
    textAlign: 'right',
    opacity: 1
  }
  return (
    <PseudoBox h="100%" position="relative" background={bg} _before={before}>
      <Editor

        width="100%"
        value={editValue}
        onValueChange={(value) => {
          setEditValue(value)
        }}
        highlight={code =>
          highlight(code, languages.json)
        }
        padding={10}
        style={{
          color: 'white',
          fontFamily: 'Inconsolata, monospace',
          fontSize: 16,
          marginLeft: `${20 + pad * 8}px`
        }}
      />
    </PseudoBox>
  )
}

EditMode.propTypes = {
  initialValue: PropTypes.string,
  onDone: PropTypes.func,
  done: PropTypes.bool
  // options: PropTypes.object
}
