import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'
import { Link as ChakraLink, Icon, useTheme } from '@chakra-ui/core'

const Link = forwardRef(function Link (
  { to, children, external, styled, ...other }, ref
) {
  const internal = /^\/(?!\/)/.test(to)
  const theme = useTheme()

  const props = { style: { textDecoration: 'none', color: styled ? theme.colors.primary[500] : null } }

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    const file = /\.[0-9a-z]+$/i.test(to) // TODO - do we use this?

    if (file) {
      return <ChakraLink {...props } href={to} innerRef={ref} {...other}>{children}</ChakraLink>
    }
    return (
      <ChakraLink {...props }
        _hover={{ opacity: 0.8 }} as={GatsbyLink} to={to} innerRef={ref} {...other}>{children}</ChakraLink>
    )
  }

  return external ? <ChakraLink {...props } href={to} isExternal={true} innerRef={ref} {...other}>{children} <Icon name="external-link" size="14px" mx="2px" mt={-1} /></ChakraLink> : <ChakraLink isExternal {...props } href={to} innerRef={ref} {...other}>{children}</ChakraLink>
})

Link.displayName = 'Link'

Link.defaultProps = {
  external: false,
  styled: false
}

Link.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
  external: PropTypes.bool,
  styled: PropTypes.bool
}
export default Link
