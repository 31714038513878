import React from 'react'
import PropTypes from 'prop-types'

export default function Form (props) {
  const { onSubmit, children, ...rest } = props

  return (
    <form onSubmit={onSubmit} { ...rest}>{children}</form>
  )
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}
