import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useStateValue } from 'contexts/BuilderContext'
import useBuilderContext from 'hooks/useBuilderContext'
import { getSchemaPath, getById } from 'utils/builderUtils'
import {
  REMOVE
} from 'actions/builderActions'

export default function BuilderElement ({ buildSchema, dragHandleComponent, defaultElementComponent, editorComponent, root, path, id, type, properties }) {
  const { setEditor, options } = useBuilderContext()
  const [state, dispatch] = useStateValue()
  console.log(buildSchema, [...path, type])
  const { viewComponent } = getSchemaPath(buildSchema, [...path, type])

  const Component = viewComponent || defaultElementComponent

  const doDelete = useCallback(

    () => {
      const { path } = getById(id, state)
      dispatch({ type: REMOVE, id, parent: root, path })
    },
    [dispatch, id, root, state]
  )
  const doEdit = useCallback(
    () => {
      setEditor({ id })
    },
    [setEditor, id]
  )
  return <>
    <Component doEdit={doEdit} doDelete={doDelete} editorComponent={editorComponent} dragHandle={options.draggable ? dragHandleComponent : null} properties={properties} type={type}/></>
}

BuilderElement.propTypes = {
  buildSchema: PropTypes.object.isRequired,
  dragHandleComponent: PropTypes.any,
  editorComponent: PropTypes.func,
  defaultElementComponent: PropTypes.func,
  path: PropTypes.array.isRequired,
  root: PropTypes.string,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  properties: PropTypes.object.isRequired
}
