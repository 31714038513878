import React from 'react'
import {
  IconButton
} from '@chakra-ui/core'
import Link from 'elements/Link/Link'
import { FORUM_ROUTE } from 'config/routes'

export default function ForumLink () {
  return (
    <Link title="Join the discussion" to={FORUM_ROUTE}>
      <IconButton
        px={0}
        mx={-2}
        icon="chat"
        color="white"
        aria-label="Forum"
        size="lg"
        variant="link"
      />
    </Link>
  )
}

ForumLink.propTypes = {}
