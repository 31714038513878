import React from 'react'
import PropTypes from 'prop-types'
import useScrollTrigger from 'hooks/useScrollTrigger'

function ElevationScroll (props) {
  const { children, opacity } = props

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  })

  return React.cloneElement(children, {
    elevate: trigger ? true : undefined,
    opacity: !trigger && !!opacity
  })
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  opacity: PropTypes.bool
}
export default ElevationScroll
