import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import BreadcrumbBar from 'elements/Nav/BreadCrumbBar'
import HeaderNav from 'elements/Frame/HeaderNav'
import { PseudoBox, Box, Flex } from '@chakra-ui/core'
import LayoutContext from 'contexts/LayoutContext'
import Footer from 'elements/Footer/Footer'
import ElevationScroll from 'elements/Frame/ElevationScroll'

const Layout = props => {
  const {
    children,
    sidebar,
    wide,
    root,
    ...rest
  } = props

  const { setSidebarVisible, sidebarVisible } = useContext(LayoutContext)

  // useEffect(() => {
  //   setSidebarVisible(!isMobile)
  // }, [setSidebarVisible, isMobile])

  // const contentUnder = sidebarVisible && isMobile

  return (
    <>
      <ElevationScroll opacity={false} {...props}>
        <HeaderNav {...rest} />
      </ElevationScroll>

      <BreadcrumbBar root={root} {...rest} />

      <Flex bg="gray.50">
        {sidebar ? (
          <PseudoBox
            display="block"
            // _before={{
            //   content: '""',
            //   position: 'absolute',
            //   top: 0,
            //   left: 0,
            //   bottom: 0,
            //   // background: 'linear-gradient( to top, #1B2830 0%, #482478 100%)',
            //   // opacity: '0.1',
            //   width: '40px',
            //   right: 0
            // }}
            position="relative"
            zIndex={1}
            // borderRightWidth={1}
            // borderRightColor="gray.200"
            // background="#FFF"
            minWidth={ '250px' }
            w={ '250px' }
            py={5}
            px={2}
          >
            <Box as="nav" mt={3} w={200} top={20} position="sticky">{sidebar}</Box>
          </PseudoBox>
        ) : (
          ''
        )}

        <Box

          minHeight="600px"
          zIndex={2}
          bg="gray.50"
          w="100%"
          ml={sidebarVisible ? 0 : -210 }
          pt={{ sm: 4, base: 5 }}
          pb={10}
          px={{ sm: 4, md: wide || sidebar ? 10 : 290 }}
        >
          {/* maxWidth={sidebar ? 1210 : 1000} */}
          <Box id="content" position="relative" pl={4} my={5} >
            {children}
          </Box>
        </Box>
      </Flex>
      {/*
      <div style={{ clear: 'both' }}/> */}
      <Footer/>
    </>

  )
}
Layout.defaultProps = {
  wide: false
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  sidebar: PropTypes.node,
  wide: PropTypes.bool,
  root: PropTypes.string
}

export default Layout
