import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useStateValue } from 'contexts/BuilderContext'
import useBuilderContext from 'hooks/useBuilderContext'
import { getSchemaPath } from 'utils/builderUtils'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import BuilderElement from './BuilderElement'
import BuilderGroup from './BuilderGroup'
import { uid } from 'react-uid'
import {
  MOVE
} from 'actions/builderActions'

const ulResetStyles = { listStyle: 'none', margin: 0, padding: 0 }
const liResetStyles = { listStyle: 'none', zIndex: 10000, margin: 0, padding: 0 }
const helperClass = (id) => `dnd-${id}`

const BuilderSortableItem = SortableElement(({ children }) => <li style={liResetStyles}>{children}</li>)

const BuilderSortableList = SortableContainer(({ items }) => {
  return (
    <ul style={ulResetStyles}>
      {items.map((value, index) => (
        <BuilderSortableItem key={uid(value)} index={index}>{value}</BuilderSortableItem>
      ))}
    </ul>
  )
})

export default function BuilderLevel ({ root, buildSchema, dragHandleComponent, defaultLevelComponent, defaultElementComponent, path, elements, depth }) {
  const { setEditor, options } = useBuilderContext()
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useStateValue()
  const schema = getSchemaPath(buildSchema, path)

  if (!schema) throw Error(`Unable to find schema definition for ${root}`)

  const actions = Object.keys(schema.elements || {}).map(type => {
    const { label, count } = schema.elements[type]

    const onCreate = () => {
      setEditor({
        parent: root,
        path: [...path, type]
      })
    }
    // Disable if we used up allowed
    const disabled = false // count <= parent.elements.filter(e => e.type === key).length
    return { type, disabled, count, label, onCreate } //, parent: root, path: [...path, type]
  })
  // We could alternatively filter them out
  // .filter(({ key, count }) => count > parent.elements.filter(e => e.type === key).length)

  const LevelComponent = defaultLevelComponent

  const items = elements.map((element, i) => {
    const { id, type, properties, elements } = element
    return <BuilderGroup key={id} element={<BuilderElement root={root} buildSchema={buildSchema} dragHandleComponent={dragHandleComponent} defaultElementComponent={defaultElementComponent} id={id} type={type} path={path} properties={properties}/>} level={<BuilderLevel root={id} buildSchema={buildSchema} defaultLevelComponent={defaultLevelComponent} dragHandleComponent={dragHandleComponent} defaultElementComponent={defaultElementComponent} elements={elements} path={[...path, type]} depth={depth + 1} />}/>
  })

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      dispatch({ type: MOVE, parent: root, from: oldIndex, to: newIndex })
    },
    [dispatch, root]
  )

  return <LevelComponent
    depth={depth}
    items={items.length ? (options.draggable ? <BuilderSortableList helperClass={helperClass(options.uid)} items={items} useDragHandle onSortEnd={onSortEnd}/> : items) : null}
    actions={actions}
  />
}

BuilderLevel.defaultProps = {
  root: null,
  depth: 0,
  path: [],
  actions: [],
  elements: []
}
BuilderLevel.propTypes = {
  dragHandleComponent: PropTypes.any,
  defaultElementComponent: PropTypes.func,
  defaultLevelComponent: PropTypes.func,
  buildSchema: PropTypes.object,
  path: PropTypes.array,
  root: PropTypes.string,
  depth: PropTypes.number,
  actions: PropTypes.array,
  elements: PropTypes.array
}
