import { createContext } from 'react'

const ServiceContext = createContext({

  service: null,
  setService: () => {}
})

export const ServiceProvider = ServiceContext.Provider
export const ServiceConsumer = ServiceContext.Consumer
export default ServiceContext
