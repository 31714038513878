
import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import reducer from 'reducers/builderReducer'
import { useImmerReducer } from 'use-immer'

export const BuilderActionContext = createContext({
  options: {},
  setOptions: () => {},
  schema: {},
  setSchema: () => {}
})

export const BuilderActionProvider = BuilderActionContext.Provider
export const BuilderActionConsumer = BuilderActionContext.Consumer
export const BuilderStateContext = createContext()
export const BuilderStateProvider = ({ reducer, initialState, children }) => (
  <BuilderStateContext.Provider value={useImmerReducer(reducer, initialState)}>
    {children}
  </BuilderStateContext.Provider>
)
BuilderStateProvider.propTypes = {
  children: PropTypes.node,
  initialState: PropTypes.object,
  reducer: PropTypes.objec
}

export const BuilderStateConsumer = BuilderStateContext.BuilderStateConsumer

export const useStateValue = () => useContext(BuilderStateContext)

// export const useStateValue = () => useImmerReducer(reducer, initialState)

export function BuilderContext (props) {
  const [editor, setEditor] = useState(null)
  const [defaultElementComponent, setDefaultElementComponent] = useState()
  const [defaultLevelComponent, setDefaultLevelComponent] = useState({})
  const [defaultEditorComponent, setDefaultEditorComponent] = useState({})
  const [options, setOptions] = useState(props.options)
  const [schema, setSchema] = useState(props.schema)

  return (

    <BuilderActionProvider value={{
      editor,
      setEditor,
      options,
      setOptions,
      schema,
      setSchema,
      setDefaultElementComponent,
      setDefaultEditorComponent,
      setDefaultLevelComponent,
      defaultLevelComponent,
      defaultEditorComponent,
      defaultElementComponent
    }}>
      <BuilderStateProvider initialState={props.definition} reducer={reducer}>

        {props.children}

      </BuilderStateProvider>
    </BuilderActionProvider>

  )
}

BuilderContext.propTypes = {
  children: PropTypes.node,
  definition: PropTypes.object,
  schema: PropTypes.object,
  options: PropTypes.object
}
