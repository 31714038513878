
import { useContext } from 'react'
import { BuilderActionContext } from 'contexts/BuilderContext'

export default function useBuilderContext () {
  const context = useContext(BuilderActionContext)
  if (context === undefined) {
    throw new Error(
      'useBuilderContext must be used within a BuilderContext Provider'
    )
  }
  return context
}
