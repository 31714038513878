import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStateValue } from 'contexts/BuilderContext'
import useBuilderContext from 'hooks/useBuilderContext'
import { getSchemaPath, getById } from 'utils/builderUtils'
import {
  CREATE,
  UPDATE
} from 'actions/builderActions'

export default function BuilderEditor ({
  editorComponent
}) {
  const { editor, schema } = useBuilderContext()
  const [form, setForm] = useState(null)
  const [state, dispatch] = useStateValue()

  const buildSchema = schema.current

  const doSubmit = useCallback(
    (values) => {
      const { id, path, parent } = editor

      dispatch({ type: id ? UPDATE : CREATE, values, path, id, parent })
    },
    [dispatch, editor]
  )

  const doClose = useCallback(
    () => {
      setForm()
    },
    [setForm]
  )

  useEffect(() => {
    setForm(editor)
  }, [setForm, editor])

  if (form) {
    const { path, properties } = getById(editor.id, state)
    const { options, editComponent } = getSchemaPath(buildSchema, path)
    const Component = (editComponent || editorComponent)

    return <Component doClose={doClose} doSubmit={doSubmit} values={properties || {}} options={options || {}}/>
  }
  return null
}

BuilderEditor.propTypes = {
  editorComponent: PropTypes.func
}
