import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { BuilderContext } from 'contexts/BuilderContext'
import { useUID } from 'react-uid'
import { addIds } from 'utils/builderUtils'

const helperClass = (id) => `dnd-${id}`

/*

Definition sample:
{ elements: [{
      id: 'TITLE-36007060-8514-11ea-a249-650ef7aeaa3c',
      type: 'title',
      properties: {
        label: 'YAY'
      },
      elements: []
    }]
    }

Schema sample:
 {
  elements: {
    title: {

      label: 'Title',
      count: 1,
      editComponent: ElementInput,
      viewComponent: Element,
      options: {

      }
    },
    field: {
      label: 'Generic Field',
      count: 2,
      ...
    }
*/

// Wrapper to create builder context
export default function Builder ({
  children,
  buildSchema,
  initialValue,
  builderOptions,
  onDone
}) {
  const { draggable, dragStyle } = builderOptions
  const options = {
    draggable: draggable || false,
    uid: useUID()
  }

  return (
    <BuilderContext schema={useRef(buildSchema)} definition={addIds(initialValue)} options={options}>
      {dragStyle ? <style dangerouslySetInnerHTML={{
        __html: `
      .${helperClass(options.uid)} { 
        ${Object.keys(dragStyle).map(k => {
        return `${k}: ${dragStyle[k]};`
      })}
      }
      `
      }} /> : null}
      {children}
    </BuilderContext>
  )
}

Builder.defaultProps = {
  builderOptions: {}
}
Builder.propTypes = {
  onDone: PropTypes.func,
  children: PropTypes.node,
  buildSchema: PropTypes.object.isRequired,
  initialValue: PropTypes.object,
  builderOptions: PropTypes.shape({
    draggable: PropTypes.bool,
    dragStyle: PropTypes.object
  })
}
