import React from 'react'
import PropTypes from 'prop-types'
import Builder from 'elements/Builder/Builder'
import BuilderTree from 'elements/Builder/BuilderTree'
import BuilderEditor from 'elements/Builder/BuilderEditor'

import {
  Flex,
  Box
} from '@chakra-ui/core'

export default function BuildMode ({ onDone, builderOptions, buildSchema, initialValue, dragHandleComponent, elementComponent, levelComponent, editorComponent }) {
  return (
    <Builder buildSchema={buildSchema} initialValue={initialValue} builderOptions={builderOptions}>
      <Flex>
        <Box flex="1" >
          <BuilderTree dragHandleComponent={dragHandleComponent} elementComponent={elementComponent} levelComponent={levelComponent}/>
        </Box>
        <Box flex="0 0 300px">
          <BuilderEditor editorComponent={editorComponent}/>
        </Box>
      </Flex>
    </Builder>
  )
}

BuildMode.defaultProp = {
  builderOptions: {
    draggable: true
  }
}
BuildMode.propTypes = {
  onDone: PropTypes.func,
  builderOptions: PropTypes.object,
  buildSchema: PropTypes.object.isRequired,
  initialValue: PropTypes.any,
  dragHandleComponent: PropTypes.func,
  elementComponent: PropTypes.func,
  levelComponent: PropTypes.func,
  editorComponent: PropTypes.func
}
