import React from 'react'
import PropTypes from 'prop-types'
import { AuthConsumer } from 'contexts/AuthContext'
import {
  Flex,
  Box,
  Image,
  IconButton,
  Button,
  Icon,
  MenuButton,
  MenuItem,
  Menu,
  MenuList,
  Avatar
} from '@chakra-ui/core'
// import Link from 'elements/Link/Link'
import Link from 'elements/Link/Link'
import NavButton from 'elements/Button/NavButton'
import logoImageDark from 'images/logo/logo-duo-dark.svg'
import logoImageLight from 'images/logo/logo-duo-light.svg'

import {
  PARAMETER_LIST_ROUTE,
  MARKETPLACE_ROUTE,
  ACCOUNT_OVERVIEW_ROUTE,
  BILLING_ROUTE,
  SERVICE_LIST_ROUTE,
  DOCS_ROUTE,
  PRICING_ROUTE,
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  REGISTER_ROUTE,
  FEATURES_ROUTE,
  MODULE_LIST_ROUTE
} from 'config/routes'

function HeaderNav (props) {
  const { opacity, elevate } = props

  const [show, setShow] = React.useState(false)
  const handleToggle = () => setShow(!show)

  const reverse = opacity && !show
  return (
    <Flex
      as="nav"
      zIndex="10"
      top="0"
      position="fixed"
      width="100%"
      // height={30}
      boxShadow={elevate ? 'md' : false }
      align="center"
      justify="space-between"
      wrap="wrap"
      padding=".7rem"
      bg={ reverse ? 'transparent' : 'white' }
      {...props}
      opacity="0.9"
    >
      <Flex align="center" ml={2} mr={10}>
        <Link to="/">
          <Image
            src={reverse ? logoImageLight : logoImageDark}
            // title="LotusEngine"
            position="relative"
            htmlWidth="150px"
          />
        </Link>
      </Flex>

      <Box display={{ sm: 'block', md: 'none' }}>
        <IconButton
          icon="hamburger"
          aria-label="Open drawer"
          onClick={handleToggle}
        >
        </IconButton>
      </Box>

      <Flex
        display={{ xs: show ? 'block' : 'none', md: 'flex' }}
        mt={{ base: 4, md: 0 }}
        width={{ xs: 'full', md: 'auto' }}
        justifyContent="flex-end"
        // flexDirection="column"
        flexGrow={1}

      >
        <NavButton
          color={reverse ? 'white' : 'gray.500' }
          variant={opacity ? 'link' : 'ghost' }
          to={PRICING_ROUTE}
        >Pricing</NavButton>
        {/* <NavButton
          color={opacity ? 'white' : 'gray.500' }
          variant={opacity ? 'link' : 'ghost' }
          to={FEATURES_ROUTE}
        >Features</NavButton> */}
        <NavButton
          color={reverse ? 'white' : 'gray.500' }
          variant={opacity ? 'link' : 'ghost' }
          to={DOCS_ROUTE}
        >Documentation</NavButton>

        <AuthConsumer>
          {({ isAuthenticating, emailHash, isAuthenticated }) => {
            return isAuthenticating ? null : (!isAuthenticated ? (
              <>
                <NavButton
                  color={reverse ? 'white' : 'gray.500' }
                  variant={opacity ? 'link' : 'ghost' }
                  to={LOGIN_ROUTE}
                >Login</NavButton>
                <NavButton
                  variant="solid"
                  variantColor="primary"
                  to={REGISTER_ROUTE}
                >Register</NavButton>
              </>
            ) : (
              <>
                <Menu>
                  <MenuButton as={Flex} ml="4" align="center">
                    <Icon name="overview" size="30px" color={opacity ? 'white' : 'primary.500' }/>
                  </MenuButton>
                  <MenuList fontSize="13px" fontWeight="600">
                    <MenuItem as={Link} to={SERVICE_LIST_ROUTE}><Icon name="dashboard" color="gray.500" mr={2}/>Dashboard</MenuItem>
                    <MenuItem as={Link} to={PARAMETER_LIST_ROUTE}><Icon name="parameter" color="gray.500" mr={2}/>Parameters</MenuItem>
                    <MenuItem as={Link} to={MODULE_LIST_ROUTE}><Icon name="module" color="gray.500" mr={2}/>Modules</MenuItem>
                    <MenuItem as={Link} to={MARKETPLACE_ROUTE}><Icon name="marketplace" color="gray.500" mr={2}/>Marketplace</MenuItem>
                    <MenuItem as={Link} to={DOCS_ROUTE}><Icon name="documentation" color="gray.500" mr={2}/>Documentation</MenuItem>
                  </MenuList>
                </Menu>
                <Menu>
                  <MenuButton as={Flex} ml="4" align="center">
                    <Avatar size="sm" src={`//0.gravatar.com/avatar/${emailHash}?d=mm`} /> <Icon name="chevron-down" alignSelf="flex-end" color={opacity ? 'white' : 'gray.500' }
                      variant={opacity ? 'link' : 'ghost' }/></MenuButton>
                  <MenuList fontSize="13px" fontWeight="600" >
                    <MenuItem as={Link} to={ACCOUNT_OVERVIEW_ROUTE}><Icon name="profile"color="gray.500" mr={2}/>Account Settings</MenuItem>
                    <MenuItem as={Link} to={LOGOUT_ROUTE}><Icon name="logout" color="gray.500" mr={2}/>Sign Out</MenuItem>
                  </MenuList>
                </Menu>
              </>
            ))
          }}
        </AuthConsumer>

      </Flex>

    </Flex>

  )
}

HeaderNav.propTypes = {
  opacity: PropTypes.bool,
  elevate: PropTypes.bool
}

export default HeaderNav
