/* eslint-disable react/prop-types */
import React from 'react'
import Root from './src/Root'

import DocFrame from 'elements/Frame/DocFrame'
import AuthFrame from 'elements/Frame/AuthFrame'
import EditorFrame from 'elements/Frame/EditorFrame'
import SettingsFrame from 'elements/Frame/SettingsFrame'
import Layout from 'elements/Frame/Layout'
// import 'prismjs/themes/prism-tomorrow.css'
// import DocMenu from 'components/Sidebar/DocMenu'
// const onRouteUpdate = require(`./gatsby/onRouteUpdate`)
// const onPreRouteUpdate = require(`./gatsby/onPreRouteUpdate`)

// exports.onRouteUpdate = () => onRouteUpdate.trustAllScripts()

// exports.onPreRouteUpdate = () => onPreRouteUpdate.killServiceWorker()
// import "typeface-montserrat"
// import "typeface-merriweather"

export const wrapPageElement = ({ element, props }) => {
  const { path } = props
  if (path && path.includes('/docs')) {
    return (

      <DocFrame
      // root="docs"
      // leftSidebar={<DocMenu />}
      //   rightSidebar={<TableOfContents contents={tocParser(mdxAST)} />}
      >
        {element}
      </DocFrame>

    )
  }
  if (path && path.includes('/auth')) {
    return (

      <AuthFrame
      // root="docs"
      // leftSidebar={<DocMenu />}
      //   rightSidebar={<TableOfContents contents={tocParser(mdxAST)} />}
      >
        {element}
      </AuthFrame>

    )
  }
  if (path && path.includes('/editor')) {
    return (

      <EditorFrame
      // root="docs"
      // leftSidebar={<DocMenu />}
      //   rightSidebar={<TableOfContents contents={tocParser(mdxAST)} />}
      >
        {element}
      </EditorFrame>

    )
  }

  // if (path && path.includes('/service')) {
  //   return (

  //     <SettingsFrame>
  //       {element}
  //     </SettingsFrame>

  //   )
  // }

  // if (path && path.includes('/settings')) {
  //   return (

  //     <SettingsFrame>
  //       {element}
  //     </SettingsFrame>

  //   )
  // }

  // if (path && path.includes('/service')) {
  //   return (

  //     <DashboardFrame>
  //       {element}
  //     </DashboardFrame>

  //   )
  // }

  // if (path && path.includes('/checkout')) {
  //   return (

  //     <CheckoutFrame
  //     // root="docs"
  //     // leftSidebar={<DocMenu />}
  //     //   rightSidebar={<TableOfContents contents={tocParser(mdxAST)} />}
  //     >
  //       {element}
  //     </CheckoutFrame>

  //   )
  // }

  if (path && (path.includes('/legal') || path.includes('/company'))) {
    return (

      <Layout
      // root="docs"
      // leftSidebar={<DocMenu />}
      //   rightSidebar={<TableOfContents contents={tocParser(mdxAST)} />}
      >
        {element}
      </Layout>

    )
  }

  // if (path.includes('/auth')) return element

  // if (path.includes('/auth')) return element

  return <> {element}</>
}

export const wrapRootElement = ({ element }) => {
  return <Root>{element}</Root>
}
