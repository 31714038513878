import { createContext } from 'react'

const LayoutContext = createContext({
  error: null,
  title: null,
  leftSidebar: null,
  sidebarVisible: true,
  rightSidebar: null,
  fullWidth: false,
  rootNode: null,
  isLoaded: false,
  setError: () => {},
  setFullWidth: () => {},
  setLeftSidebar: () => {},
  setSidebarVisible: () => {},
  setRightSidebar: () => {},
  setRootNote: () => {},
  setLoaded: () => {},
  loadingBarActive: false,
  setLoadingBarActive: () => {},
  setTitle: () => {}
})

export const LayoutProvider = LayoutContext.Provider
export const LayoutConsumer = LayoutContext.Consumer
export default LayoutContext
