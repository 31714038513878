import React from 'react'
import PropTypes from 'prop-types'

export default function BuilderGroup ({ element, level }) {
  return (<div>
    {element}
    {level}
  </div>)
}

BuilderGroup.propTypes = {
  element: PropTypes.node.isRequired,
  level: PropTypes.node.isRequired
}
