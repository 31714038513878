import { produce } from 'immer'
import { v4 as uuid } from 'uuid'
import { emptyItem, getById, updateById, moveElement } from 'utils/builderUtils'
import {
  REMOVE,
  CREATE,
  UPDATE,
  MOVE
} from 'actions/builderActions'

const reducer = produce((state, action) => {
  const { type } = action

  switch (type) {
    case CREATE: {
      // Get parent if not root
      const root = action.parent ? getById(action.parent, state) : state

      // Append to children
      const elements = [...root.elements, {
        ...emptyItem,
        id: uuid(),
        type: action.path[action.path.length - 1],
        properties: action.values
      }]

      return updateById(action.parent, state, { elements })
    }
    case UPDATE: {
      return updateById(action.id, state, { properties: action.values })
    }
    case REMOVE: {
      const root = action.parent ? getById(action.parent, state) : state

      // Remove from children
      const elements = root.elements.filter(e => e.id !== action.id)

      return updateById(action.parent, state, { elements })
    }
    case MOVE: {
      const root = action.parent ? getById(action.parent, state) : state
      return updateById(action.parent, state, { elements: moveElement(root.elements, action.from, action.to) })
    }
  }
})
export default reducer
