import React from 'react'
import PropTypes from 'prop-types'
import { useStateValue } from 'contexts/BuilderContext'
import { sortableHandle } from 'react-sortable-hoc'
import useBuilderContext from 'hooks/useBuilderContext'

import BuilderLevel from './BuilderLevel'

export default function BuilderTree ({
  elementComponent,
  levelComponent,
  dragHandleComponent
}) {
  const { schema } = useBuilderContext()
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useStateValue()

  const buildSchema = schema.current

  const DragHandle = dragHandleComponent ? sortableHandle(() => dragHandleComponent) : null

  return (<BuilderLevel buildSchema={buildSchema} dragHandleComponent={<DragHandle/>} defaultLevelComponent={levelComponent} defaultElementComponent={elementComponent} elements={state.elements}></BuilderLevel>)
}

BuilderTree.propTypes = {
  dragHandleComponent: PropTypes.any,
  elementComponent: PropTypes.func,
  levelComponent: PropTypes.func
}
