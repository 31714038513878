import { createContext } from 'react'
import { SERVICE_LIST_ROUTE } from 'config/routes'

const AuthContext = createContext({
  isAuthenticating: true,
  setIsAuthenticating: () => {},
  isAuthenticated: false,
  setAuthenticated: () => {},
  emailHash: null,
  setEmailHash: () => {},
  userId: null,
  setUserId: () => {},
  accountId: null,
  setAccountId: () => {},
  postAuthRoute: SERVICE_LIST_ROUTE,
  setPostAuthRoute: () => {},
  postAuthPayload: {},
  setPostAuthPayload: () => {}
})

export const AuthProvider = AuthContext.Provider
export const AuthConsumer = AuthContext.Consumer
export default AuthContext
