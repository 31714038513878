import React from 'react'
import SideMenu from 'elements/Sidebar/SideMenu'
import { ACCOUNT_OVERVIEW_ROUTE, UPDATE_EMAIL_ROUTE, UPDATE_PASSWORD_ROUTE } from 'config/routes'

const settingsRoutes = [
  {
    text: 'Account Overview',
    icon: 'profile',
    url: ACCOUNT_OVERVIEW_ROUTE
  },
  {
    text: 'Update Email',
    icon: 'email',
    url: UPDATE_EMAIL_ROUTE
  },
  {
    text: 'Change Password',
    icon: 'lock',
    url: UPDATE_PASSWORD_ROUTE
  }
  // {
  //   text: 'Global Parameters',
  //   icon: ParameterIcon,
  //   url: PARAMETER_LIST_ROUTE
  // }

]

export default function SettingsMenu () {
  return (
    <nav>
      <SideMenu items={settingsRoutes} />
    </nav>
  )
}
