import React from 'react'
import PropTypes from 'prop-types'
import Link from 'elements/Link/Link'
import { Heading, Box, List, ListItem } from '@chakra-ui/core'
import { uid } from 'react-uid'

export default function FooterNavList ({ title, links }) {
  return (
    <Box>
      <Heading mb="3" color="white" as="h4" size="sm">
        {title}
      </Heading>
      <List spacing={3}>
        {links.map(({ name, url, external = false }) => (
          <ListItem fontSize="sm" key={uid(name)}><Link
            external={external}
            color="silver"
            to={url}
          >{name}</Link></ListItem>
        ))}
      </List>
    </Box>
  )
}

FooterNavList.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired
}
