import React from 'react'
import PropTypes from 'prop-types'
import { Button, Flex } from '@chakra-ui/core'
import { Link } from '@reach/router'

export default function NavButton (props) {
  const { children, ...rest } = props

  return (
    <Flex align="flex-end">
      <Button
        h="40px"
        // display="block"
        // textTransform="uppercase"
        mt={{ base: 4, md: 0 }}
        mr={{ base: 0, md: 4 }}
        fontSize="1em"
        {...rest}
        as={Link}
        style={{ textDecoration: 'none' }}
        _hover={{ opacity: 0.8 }}
      >
        {children}
      </Button>
    </Flex>
  )
}

NavButton.propTypes = {
  children: PropTypes.node
}
